import { jsx as m, jsxs as p } from "react/jsx-runtime";
import { FormControl as y, Select as b, MenuItem as g } from "@mui/material";
import { Grid as A } from "../Grid/Grid.js";
import { Typography as B } from "../Typography/Typography.js";
import { d as C } from "../../../KeyboardArrowDown-695f8c89.js";
import { styles as s } from "./Select.styles.js";
import "../../../Chatbot.style-6e4bc6f8.js";
import "react";
import { Checkbox as S } from "../Checkbox/Checkbox.js";
import { Divider as E } from "../Divider/Divider.js";
import "../FormControl/FormControl.js";
import "../FormControlLabel/FormControlLabel.js";
import "../../../map-85279df3.js";
import "../../../server.browser-ec1ee1ca.js";
import "../Paper/Paper.js";
import { Box as j } from "../Box/Box.js";
import "../Radio/Radio.js";
import "../RadioGroup/RadioGroup.js";
import "../Stepper/Stepper.js";
import "../Step/Step.js";
import "../StepLabel/StepLabel.js";
import "../../../style.module-3bbdaa05.js";
import "../../../DateTimeSelectorMenu-84657d02.js";
import "moment-timezone";
import "../../../runtime-6393d96e.js";
import "../../../TableContext-c60e49ca.js";
import "../../ReactTable/SearchFilter.js";
import "../../ReactTable/TablePaginationActions.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../../transform-17824b7f.js";
import "../../molecules/NetworkMap/NetworkMapUtils.js";
import "../../../ChatbotContext-74017a6b.js";
function nr({
  id: a,
  DropdownIcon: l,
  options: e,
  placeholder: h,
  value: t,
  variant: u,
  sx: n,
  customFooter: d,
  ...x
}) {
  return /* @__PURE__ */ m(y, { variant: u, hiddenLabel: !0, children: /* @__PURE__ */ p(
    b,
    {
      id: a,
      labelId: `${a}-label`,
      displayEmpty: !0,
      IconComponent: (r) => l ? /* @__PURE__ */ m(l, { ...r }) : /* @__PURE__ */ m(C, { ...r }),
      sx: [s.common, ...Array.isArray(n) ? n : [n]],
      renderValue: (r) => {
        const f = Array.isArray(r);
        if (!r || f && r.length === 0)
          return h;
        if (f)
          return e.filter((o) => r.includes(o.value)).map((o) => o == null ? void 0 : o.label).join(", ");
        const i = e.find((c) => c.value === r);
        return /* @__PURE__ */ p(A, { container: !0, sx: s.selectedContainer, children: [
          i == null ? void 0 : i.icon,
          i == null ? void 0 : i.label
        ] });
      },
      value: t,
      ...x,
      children: [
        e.map((r) => /* @__PURE__ */ p(
          g,
          {
            value: r.value,
            sx: { display: "flex", gap: 1 },
            children: [
              Array.isArray(t) && /* @__PURE__ */ m(S, { checked: t == null ? void 0 : t.includes(r.value) }),
              r.icon,
              /* @__PURE__ */ m(B, { sx: { fontSize: "12px", fontWeight: 400 }, children: r.label })
            ]
          },
          r.id
        )),
        d && /* @__PURE__ */ p(j, { children: [
          /* @__PURE__ */ m(E, { sx: { marginTop: "8px", marginBottom: "8px", borderColor: "#EBEBEB" } }),
          d
        ] })
      ]
    }
  ) });
}
export {
  nr as Select
};
