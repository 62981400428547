import React, { useEffect, useState, useMemo } from "react";
import { history, updateURL } from '~/helpers/history';
import { DISCONNECTED, VIEW } from "../../constants";
import { Types } from "../../constants/types";
import { useInterval } from "../../hooks/useInterval";
import {
  deviceGroupActions,
  deviceGroupSelectedIDs,
  fetchDeviceGroups,
  subscriberDeviceGroups,
} from "../../store/context/deviceGroupSlice";
import {
  fetchDNN,
  fetchDevices,
  fetchDevicesLatestMetrics,
  subscriberActions,
  subscriberData,
  fetchSubscriberDeviceInfo,
  subscriberPreset,
  subscriberSelected,
} from "../../store/context/subscriberSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Device } from "../../types/device";
import { DeviceGroup } from "../../types/deviceGroup";
import moment from "moment-timezone";
import { utilityActions } from "~/store/context/utilitySlice";
import { SelectChangeEvent } from "@mui/material";

export const defaultViewHiddenColumns = [
  "deviceGroupNameForTopN",
  "runtimeInfo.ipv4",
  "runtimeInfo.apn",
  "ueId",
  "uplinkSinr",
  "pDownlinkCqi",
  "pDownlinkMcs",
  "uplinkMcs",
  "p1DownlinkBler",
  "p2DownlinkBler",
  "uplinkBler",
  "downlinkRate",
  "uplinkRate",
  "pathloss",
  "txPower",
  "signalStrength",

  "PHY-DL",
  "RI-Rx-Tx",
  "PHY-UL",
  "rssi"
];
export const topNViewHiddenColumns = [
  "deviceGroupName",
  "lte.state",
  "status",
  "statusMessage",
  "runtimeInfo.apn",
  "iccid",
  "simState",
];

export const AIRSPANColumns = [
  "PHY-DL",
  "RI-Rx-Tx",
  "PHY-UL",
  "rssi"
]
export const HiddenColumnsForAirspan = [
  "downlinkRate",
  "uplinkRate",
  "pathloss",
  "txPower",
]
export interface DeviceX extends Partial<Device> {
  deviceGroupName?: string;
  emptyObj?: boolean;
}

export const useSubsciberPanel = () => {
  const dispatch = useAppDispatch();
  const subscribers = useAppSelector(subscriberData);
  const deviceGroups = useAppSelector(subscriberDeviceGroups);
  const selectedDevice = useAppSelector(subscriberSelected);
  const selectedGroupIDS = useAppSelector(deviceGroupSelectedIDs);
  const loadingStatus = useAppSelector((state) => state.subscriber.status);

  const [tabIndex, setTabIndex] = React.useState("summary");
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [simTypeFilter, setSimTypeFilter] = useState('ALL');

  const preset = useAppSelector(subscriberPreset);

  const [isLoading, setIsLoading] = useState(true);

  function getObjectFromID(id: string) {
    const type = id.split("-")[0];
    switch (type) {
      case Types.device:
      case Types.endUserDevice:
        return {
          type: Types.device,
          obj: subscribers.find((item) => item.id === id),
        };
      case Types.deviceGroup:
        return {
          type: Types.deviceGroup,
          obj: deviceGroups.find((item) => item.id === id),
        };
    }
  }

  const handleSimTypeChange = (event: SelectChangeEvent<string>) => {
    setSimTypeFilter(event.target.value);
  };

  // update the device list when the device group is changed
  const devices = useMemo(() => {
    const _rows = SyncwithGroups(subscribers, deviceGroups, preset) ?? [];

    const filteredDevices =
      preset === VIEW.TOP_N_VIEW
        ? _rows.filter((item) => {
          const currentState = item?.runtimeInfo?.status ?? DISCONNECTED;
          return (
            ![DISCONNECTED].includes(currentState) &&
            (selectedGroupIDS.includes(item.groupId!) || selectedGroupIDS.includes(Types.all))
          );
        })
        : _rows.filter((item) => {
          return selectedGroupIDS.includes(item.groupId!) || selectedGroupIDS.includes(Types.all);
        });

    // Apply simType filter
    const devices =
      simTypeFilter === 'ALL'
        ? filteredDevices
        : filteredDevices.filter((device) => device.simType === simTypeFilter);

    if (devices?.length > 0) setIsLoading(false);
    return devices;
  }, [subscribers, deviceGroups, preset, selectedGroupIDS, simTypeFilter]);

  // for loading state update
  useEffect(() => {
    if (loadingStatus === "devices_loaded") {
      setIsLoading(false);
    }
  }, [loadingStatus]);

  // get the params from the url and set the selected object in the store
  useEffect(() => {
    function handleSelectionByURL() {
      const params = new URLSearchParams(window.location.search);
      const id = params.get("id");
      console.log("id exist subs", id);
      if (id) {
        const selected = getObjectFromID(id);
        if (selected?.obj) {
          switch (selected.type) {
            case Types.device:
              dispatch(subscriberActions.setValues(selected.obj as Device));
              const index = devices.findIndex((item) => item.id === id);
              setSelectedIndex(index);
              break;
            case Types.deviceGroup:
              dispatch(deviceGroupActions.setValues(selected.obj as DeviceGroup));
              break;
          }
        }
      } else {
        console.log("id not exist subs", id);
        // if the id is not found on table then select the first one
        const first = devices[0] as Device;
        if (first) {
          dispatch(subscriberActions.setValues(first));
          setSelectedIndex(0);
          updateURL({
            id: first.id!,
            group: first.groupId,
            menuID: "all",
          });
        }
      }
      const menuID = params.get("menuID");
      if (menuID) {
        dispatch(deviceGroupActions.setSelectedDeviceGroupIDs([menuID] as string[]));
      }

      const tab = params.get("tab");
      if (tab) {
        setTabIndex(tab);
      }

      // set preset based on the url
      const view = params.get("preset");
      if (view) {
        dispatch(subscriberActions.setPreset(view));
        dispatch(
          subscriberActions.setHiddenColumns(view === VIEW.TOP_N_VIEW ? topNViewHiddenColumns : defaultViewHiddenColumns)
        );
      }
    }

    handleSelectionByURL();

    const unlisten = history.listen(() => {
      handleSelectionByURL();
    });

    return () => { unlisten(); };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subscribers.length, deviceGroups.length]);

  useInterval(
    async () => {
      dispatch(fetchDeviceGroups());
      await dispatch(fetchDevices());
      dispatch(fetchDevicesLatestMetrics());
      dispatch(fetchSubscriberDeviceInfo());
    },
    1000 * 30,
    true
  );

  function cleanAll() {
    setIsLoading(true);
  }

  useEffect(() => {
    dispatch(fetchDNN());
    return () => {
      cleanAll();
    };
  }, [dispatch]);

  function SyncwithGroups(devices: Device[], deviceGroups: DeviceGroup[], preset: string) {
    if (deviceGroups.length > 0) {
      const rows = devices.map((row) => {
        const data = { ...row } as DeviceX;
        const group = deviceGroups.find((device) => row.groupId === device.id);
        if (group) {
          data.deviceGroupName = group.name;
        }
        return data;
      });
      /* Nort required below code now. it was added when we have grouping on only one react table without tree left side.
     //   This will create one more row with empty data to isplay in old layout. new layout not required so commencted below code, */
      // deviceGroups.forEach((item) => {
      //   let isGroupExisted = false;
      //   rows.every((device) => {
      //     if (item.name === device.deviceGroupName) {
      //       isGroupExisted = true;
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });

      //   // Push a Blank Group if it doesn't include a device
      //   if (!isGroupExisted && VIEW.DEFAULT_VIEW === preset) {
      //     console.log("empty obj created")
      //     rows.push({ deviceGroupName: item.name, emptyObj: true });
      //   }
      // });

      return rows;
    }
  }

  useIntervalSetter("3hour", tabIndex);


  function handlePresetChange(event: SelectChangeEvent<string>) {
    dispatch(subscriberActions.setPreset(event.target.value));
    if (event.target.value === VIEW.TOP_N_VIEW) {
      dispatch(subscriberActions.setHiddenColumns(topNViewHiddenColumns));
    } else {
      dispatch(subscriberActions.setHiddenColumns(defaultViewHiddenColumns));
    }
    updateURL({
      preset: event.target.value,
    });
  }

  return {
    isLoading,
    setIsLoading,
    data: subscribers,
    deviceGroups,
    preset,
    devices,
    selectedDevice,
    tabIndex,
    setTabIndex,
    selectedIndex,
    setSelectedIndex,
    handlePresetChange,
    handleSimTypeChange,
    simTypeFilter
  };
};



/**
 * The function `useIntervalSetter` in TypeScript React sets a default time range to 6 hours if the
 * time difference between start and end times is greater than 2 days to improve user experience with
 * large data loading.
 * @param interval - The `interval` parameter in the `useIntervalSetter` function is a string that
 * specifies the time interval for setting the default time range. In this case, it is set to "6hour".
 * @param [difference=2] - The `difference` parameter in the `useIntervalSetter` function represents
 * the threshold value in days. If the time difference between the `startTimeI` and `endTimeI` exceeds
 * this threshold, the default time range will be set to 6 hours to improve user experience due to
 * potentially large data that
 */
export function useIntervalSetter(interval: "3hour", tab: string, difference = 2) {

  const dispatch = useAppDispatch();
  const startTimeI = useAppSelector((state) => state.utility.time.startTime);
  const endTimeI = useAppSelector((state) => state.utility.time.endTime);

  // if timedifference is greater than 2 days, then we should set the default time range to 6 hours as due to large data, it will take time to load the data and it will not be a good user experience
  useEffect(() => {
    console.log(">>> in useEffect of Time Setter", moment.unix(endTimeI).diff(moment.unix(startTimeI), "days"));
    if (moment.unix(endTimeI).diff(moment.unix(startTimeI), "days") > difference) {
      dispatch(utilityActions.setTimeInterval({ interval, shouldUpdateTime: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difference, dispatch, interval, tab]);
}